
export default {
  data() {
    return {
      openCart: false,
      openMegaMenu: false,
      selected: {},
      mega_menu:this.prepareMega(),
      cart: { items: [] },
      notSticky:false,
    };
  },
  watch: {
    "$route.name": {
      handler(v, o) {
        this.openMegaMenu = false;
      },
    },
    "$route.params": {
      handler(v, o) {
        this.openMegaMenu = false;
      },
    },
    openCart: function (v) {
      if (v) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    },
    openMegaMenu: function (v) {
      if (v) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.cart = this.$hyperCart.getCart();
    this.$nuxt.$on("updateCart", () => {
      this.cart = this.$hyperCart.getCart();
    });
    this.$nuxt.$on("openMenu", () => {
      this.openMegaMenu = true;
    });
    this.$nuxt.$on("closeMenu", () => {
      this.openMegaMenu = false;
    });
  },
  methods: {
    handleScroll() {
      if (!document.getElementById("beHeader")) {
        return;
      }
      if (
        window.pageYOffset >
        (screen.width < 600
          ? 32
          : document.getElementById("beHeader").offsetHeight)
      ) {
        if (this.notSticky) this.notSticky = false;
      } else {
        if (!this.notSticky) this.notSticky = true;
      }
    },
    prepareMega(){
    let temp = JSON.parse(JSON.stringify(this.$store.getters.store.eshop.mega_menu));
    temp = temp.map(c=>{
      let full = false;
      c.children.forEach(cc=>{
        if(cc.children && cc.children.length){
          full = true;
        }
      });
      c.full = full;
      return c;
    });
    return temp;
    },
    selectChild(b){
      this.selected = JSON.parse(JSON.stringify(b));
      this.$forceUpdate();
    },
    
  },
};
